<template>
  <div>
    <app-collapse-item title="Show Filters">
      <b-row>
        <b-col md="4">
          <b-form-group label="filter by type" label-for="mc-type">
            <v-select
              :clearable="false"
              placeholder="Type"
              v-model="filters.type"
              :options="typeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="filter by movie" label-for="mc-movie">
            <v-select
              id="movie_id"
              label="full_title"
              placeholder="Movie/Title"
              :options="$store.state.master.movies"
              :reduce="(movie) => movie.movie_id"
              v-model="filters.movie_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="filters.type == 'Sales' ? 'Licensee' : 'Licensor'"
            label-for="mc-licensee"
          >
            <v-select
              id="vendor"
              label="company"
              :options="$store.state.master.vendors"
              :reduce="(vendor) => vendor.vendor_id"
              v-model="filters.vendor_id"
              :placeholder="filters.type == 'Sales' ? 'Licensee' : 'Licensor'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Territory">
            <treeselect
              :options="$store.state.master.territory_tree"
              multiple
              v-model="filters.territories"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Rights">
            <treeselect
              :options="$store.state.master.rights_tree"
              multiple
              v-model="filters.rights"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Language">
            <v-select
              placeholder="Language"
              label="lbl_name"
              :options="$store.state.master.language"
              :reduce="(label) => label.lbl_id"
              multiple
              :close-on-select="false"
              v-model="filters.languages"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Exclusive">
            <v-select
              :options="exclusiveOptions"
              :reduce="(item) => item.value"
              v-model="filters.exclusive"
              placeholder="Yes / No"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Begin From">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              class="form-control"
              v-model="filters.begin_after"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Begin To">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              class="form-control"
              v-model="filters.begin_before"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="End From">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              class="form-control"
              v-model="filters.end_after"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="End To">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              class="form-control"
              v-model="filters.end_before"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Cast" label-for="mc-cast">
            <v-select
              id="mc-cast"
              label="lbl_name"
              placeholder="Select Cast"
              :options="$store.state.master.cast"
              :reduce="(label) => label.lbl_id"
              v-model="filters.cast"
              multiple
              :close-on-select="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Director" label-for="mc-director">
            <v-select
              id="mc-director"
              label="lbl_name"
              placeholder="Select Director"
              :options="$store.state.master.director"
              :reduce="(label) => label.lbl_id"
              v-model="filters.director"
              multiple
              :close-on-select="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col>
          <b-button @click="onChange">FIND</b-button>
        </b-col>
        <b-col>
          <b-button @click="onReset">RESET</b-button>
        </b-col>
      </b-row>
    </app-collapse-item>
    <b-card>
      <p>Total {{ pagination.total }} Rights</p>
    </b-card>
    <b-card no-body>
      <b-row class="my-1">
        <b-col>
          <v-select
            @input="onChange"
            v-model="pagination.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pagination.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries per page</label>
        </b-col>
        <b-col class="d-flex justify-content-end mr-1">
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mb-1"
            size="sm"
            right
            text="Export"
            variant="outline-primary"
          >
            <b-dropdown-item @click="exportExcel">Excel</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <template #title>
        <feather-icon icon="CheckCircleIcon" />
        <span>{{ item.right_name }}</span>
      </template>
      <b-table
        :fields="fields"
        :items="rightsReportData"
        responsive
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(sr)="data">
          {{
            data.index + 1 + (pagination.currentPage - 1) * pagination.perPage
          }}
        </template>
        <template #cell(exclusive)="data">
          <b-badge
            class="ml-1"
            v-b-tooltip.hover
            title="exclusive"
            :variant="data.item.exclusive == '1' ? 'success' : 'warning'"
          >
            {{ data.item.exclusive == "1" ? "Yes" : "No" }}
            <!-- <feather-icon icon="StarIcon" class="mr-25" /> -->
          </b-badge>
        </template>
        <template #head(company)>
          <span>{{
            filters.type && filters.type == "Sales" ? "Licensee" : "Licensor"
          }}</span>
        </template>
        <template #cell(begin)="data">
          {{ moment(data.item.contract_begin).format("DD-MM-YYYY") }}
        </template>
        <template #cell(end)="data">
          {{ moment(data.item.contract_end).format("DD-MM-YYYY") }}
        </template>
        <template #cell(status)="data">
          {{ data.item.contract_end ? getDiffTime(data.item) : "" }}
        </template>
      </b-table>
      <b-row class="mb-1">
        <b-col md="4" class="mb-1 mb-md-0"> </b-col>
        <b-col md="8" class="d-flex justify-content-end">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            @change="onPaginationChange"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
      <!-- </b-tab> -->
    </b-card>
    <!-- </b-tabs> -->
  </div>
</template>

<script>
import {
  BFormGroup,
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BCardText,
  BTabs,
  BTab,
  VBTooltip,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import { GetRightsReport } from "@/apiServices/ReportsServices";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { labels } from "@/utils/constants";
import { downloadFromURL } from "@/utils/helpers";
import flatPickr from "vue-flatpickr-component";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BFormGroup,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    VBTooltip,
    BPagination,
    BDropdown,
    BDropdownItem,
    Treeselect,
    flatPickr,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      filters: {
        type: "Acquisition",
        movie_id: null,
        vendor_id: null,
        rights: [],
        languages: [],
        territories: [],
        exclusive: "",
        begin_after: "",
        begin_before: "",
        end_after: "",
        end_before: "",
        cast: [],
        director: [],
      },
      typeOptions: ["Acquisition", "Sales"],
      exclusiveOptions: [
        { label: "Yes", value: "Y" },
        { label: "No", value: "N" },
      ],
      rightsReportData: [],
      fields: [
        { key: "sr", label: "SR" },
        { key: "movie", label: "Movie" },
        { key: "right", label: "Right" },
        { key: "territory", label: "Territory" },
        { key: "language", label: "Language" },
        { key: "exclusive", label: "Exclusive" },
        { key: "begin", label: "Begin" },
        { key: "end", label: "End" },
        { key: "status", label: "Status" },
        { key: "company", label: "Licensor" },
      ],
    };
  },
  methods: {
    moment,
    getDiffTime(contract) {
      const end = moment(contract.contract_end);
      const now = moment();

      if (
        contract.contract_begin &&
        moment(contract.contract_begin).isAfter(now)
      ) {
        return `Not Started`;
      }
      if (end.isBefore(moment())) {
        return this.filters.type === "Acquisition" ? `Expired` : `Open`;
      }

      const duration = moment.duration(end.diff(moment()));

      let str =
        this.filters.type === "Acquisition" ? "Expiring in " : "Opening in";
      if (duration.years()) str += `${duration.years()} years, `;
      if (duration.months()) str += `${duration.months()} months, `;
      if (duration.days()) str += `${duration.days()} days`;

      return str;
    },
    onReset() {
      this.filters = {
        type: "Acquisition",
        movie_id: null,
        vendor_id: null,
        rights: [],
        languages: [],
        territories: [],
        exclusive: "",
        begin_after: "",
        begin_before: "",
        end_after: "",
        end_before: "",
        cast: [],
        director: [],
      };
      this.onChange();
    },
    onChange() {
      this.$nextTick(() => {
        this.pagination = {
          ...this.pagination,
          currentPage: 1,
          total: 0,
        };
        this.getRightsReport();
      });
    },
    onPaginationChange() {
      this.$nextTick(() => {
        this.getRightsReport();
      });
    },
    async getRightsReport() {
      // this.rightsReportData = [];
      try {
        const response = await GetRightsReport({
          limit: this.pagination.perPage,
          page: this.pagination.currentPage,
          ...this.filters,
        });
        if (response.status) {
          this.rightsReportData = response.data.data.data;
          if (this.pagination.currentPage == 1) {
            this.pagination.total = response.data.data.count;
          }
        }
      } catch (error) {
        console.log("Error in GetReportData ", error.message);
      }
    },

    async exportExcel() {
      const loader = this.$loading.show();

      fetch(this.BASE_URL + "/report/rights", {
        method: "POST",
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify({ ...this.filters, isExcelExport: true }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(_url, "RightReport", "xlsx");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  mounted() {
    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.director });
    this.$store.dispatch("master/setLabelMaster", { key: labels.cast });

    // Tree Data
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
    this.getRightsReport();
  },
};
</script>
